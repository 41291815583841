import React, { useEffect } from 'react';
import AOS from 'aos';

const creativetech = {
    class:{
        col1:'col-12 col-lg-6 d-flex text-white text-center',
        col2:'col-12 col-lg-6 d-flex align-items-center text-white',
        col3:'col-12 d-flex quartz justify-content-center text-center'
    },
    item1: {
        bg:'./images/creativetech.png',
        copy:<>CREATIVE<br/>TECH</>,
        class:'article-thumbnail article-thumbnail-color inter-extra-bold fs-40 fs-lg-60 lh-100',
    },
    item2: {
        copy:'Our Creative Technology team merges digital craftsmanship with forward-thinking solutions to evoke feelings and create memories. We specialise in interactive experiences, immersive out of home, augmented reality, and AI solutions. Whether its building immersive web experiences, creating AI content or exploring the latest in virtual technology, our team integrates cutting-edge tech with seamless design, deepening the connection between brands and their audiences and leaving a lasting memory',
        class:'inter-regular scotch-mist fs-20'
    },
    item3: {
        copy:'Interactive Activations + Immersive OOH + AI content + AR/VR/XR + Immersive Web',
        class:'inter-bold fs-20'
    }
}

const CreativeTech = () => {

    useEffect(() => {
    AOS.init({ duration: 500 });
    }, []);

    const fadeUp = {'data-aos':'fade-up'};
       
    return (
        <>       
        <section className='container px-4 py-5 px-lg-100 pt-lg-100 article'>
        <span className='anchor' id={`creativetech`}></span>
            <div className='row g-5 flex-row-reverse'>
                <div className={creativetech.class.col1}>
                    <div className='article-thumbnail-bg' data-aos-ignore='true'>
                        <div className='article-thumbnail-bg' style={{ backgroundImage: `url(${creativetech.item1.bg})` }}  {...fadeUp}>
                            <div className={creativetech.item1.class} >{creativetech.item1.copy}</div>
                        </div>
                    </div>
                </div>
                <div className={creativetech.class.col2} {...fadeUp}>
                    <div className={creativetech.item2.class}>{creativetech.item2.copy}</div>
                </div>
                <div className={creativetech.class.col3} {...fadeUp}>
                    <div className={creativetech.item3.class}>{creativetech.item3.copy}</div>
                </div>
            </div>
        </section>
        </>
        )
}

export default CreativeTech;