import React, { useEffect, useState } from 'react';
import AOS from 'aos';

const preLoader = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
    <circle
      transform='rotate(0)'
      transform-origin='center'
      fill='none'
      stroke='#FF156D'
      strokeWidth='15'
      strokeLinecap='round'
      strokeDasharray='230 1000'
      strokeDashoffset='0'
      cx='100'
      cy='100'
      r='70'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        from='0'
        to='360'
        dur='2s'
        repeatCount='indefinite'
      />
    </circle>
  </svg>
);

const tilesCta = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' className='svg-icon'>
    <g clipPath='url(#cta1)'>
      <circle cx='25' cy='25' r='25' />
      <path
        className='svg-icon-path'
        d='M35.1175 22.5231C36.6582 23.9734 36.6582 26.4223 35.1175 27.8727L22.4551 39.7922C19.2276 42.8303 14.3776 38.6115 16.9393 34.9943L22.3737 27.321C23.2746 26.0489 23.2746 24.3468 22.3737 23.0748L16.9394 15.4014C14.3776 11.7842 19.2276 7.56538 22.455 10.6035L35.1175 22.5231Z'
        fill='#5C31D3'
      />
    </g>
    <defs>
      <clipPath id='cta1'>
        <rect width='75' height='75' />
      </clipPath>
    </defs>
  </svg>
);

const filmtiles = {
  colClass: 'col-12 col-lg-4 px-0 d-flex align-items-center justify-content-center text-center',
  item1: {
    bg: './images/film-img-01.png',
    src: './images/film-video-01.mp4',
    class: 'tiles-thumbnail me-lg-4',
  },
  item2: {
    bg: './images/film-img-02.png',
    src: './images/film-video-02.mp4',
    class: 'tiles-thumbnail mx-lg-3',
  },
  item3: {
    bg: './images/film-img-03.png',
    src: './images/film-video-03.mp4',
    class: 'tiles-thumbnail ms-lg-4',
  },
};

const FilmTiles = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
    preloadAllVideos();
  }, []);

  const fadeUp = { 'data-aos': 'fade-up' };

  const [showModal, setShowModal] = useState(false);
  const [videoPath, setVideoPath] = useState('');
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const openModal = (path) => {
    setVideoPath(path);
    setShowModal(true);
    setIsVideoLoading(true);
  };

  const closeModal = () => {
    setVideoPath('');
    setShowModal(false);
    setIsVideoLoading(false);
  };

  const handleVideoLoaded = () => {
    setIsVideoLoading(false);
  };

  const preloadAllVideos = () => {
    Object.keys(filmtiles)
      .filter((key) => key !== 'colClass')
      .forEach((key) => {
        const video = document.createElement('video');
        video.src = filmtiles[key].src;
        video.preload = 'auto'; // Ensures the browser fetches and caches the video
        video.load();
      });
  };

  return (
    <section className='container px-4 pb-5 px-lg-100 pb-lg-100 tiles'>
      <div className='row g-5 w-100 justify-content-evenly'>
        {/* Thumbnail columns */}
        {Object.keys(filmtiles).map((key, index) => {
          if (key === 'colClass') return null;

          const tile = filmtiles[key];
          return (
            <div key={index} className={filmtiles.colClass} {...fadeUp}>
              <div
                className={tile.class}
                style={{ backgroundImage: `url(${tile.bg})` }}
                onClick={() => openModal(tile.src)}
              >
                {tilesCta}
              </div>
            </div>
          );
        })}
      </div>

      {/* Modal for full-screen video */}
      {showModal && (
        <div className='modal-overlay' onClick={closeModal}>
          <div
            className='modal-fullscreen'
            onClick={(e) => e.stopPropagation()}
          >
            {/* Preloader */}
            {isVideoLoading && (
              <div className='video-preloader'>
                {preLoader}
              </div>
            )}
            {/* Video Player */}
            <video
              preload='auto'
              src={videoPath}
              controls
              autoPlay
              className='video-player'
              onLoadedData={handleVideoLoaded}
            ></video>
            <button className='close-button' onClick={closeModal}>
              &times;
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default FilmTiles;
