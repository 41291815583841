import React, { useEffect } from 'react';
import AOS from 'aos';

import './whatwedo.scss';

const whatwedo = {
    id:'whatwedo',
    class:{
        item:'col-12 p-0 pb-3',
    },
    title: {
        copy:<>WHAT WE DO</>,
        class:'px-0 bilobaflower inter-extra-bold fs-45 pb-5 fs-lg-56'
    },
    links:{
        class:'laserlemon text-decoration-none inter-extra-bold fs-4 fs-lg-36'
    },
    link1: {
        copy:'STUDIO',
        href:'#studio'
    },
    link2: {
        copy:'FILM',
        href:'#film'
    },
    link3: {
        copy:'CREATIVE TECH',
        href:'#creativetech',
    },
    link4: {
        copy:'AI COLLECTIVE',
        href:'#aicollective',
    }
}

const WhatWeDo = () => {

    useEffect(() => {
            AOS.init({ duration: 500 });
          }, []);
    
    const fadeUp = {'data-aos':'fade-up'};

    return (
    
    <>
        <section {...fadeUp} className='container px-4 pb-4 px-lg-100'>
        
        <span className='anchor' id={`whatwedo`}></span>
            <div className='row m-0 text-center'>
                <span className={whatwedo.title.class}>
                    {whatwedo.title.copy}
                </span>
            </div>
            <div className='row m-0'>
                <ul className='p-0 d-flex flex-column align-items-center flex-lg-row justify-content-evenly'>
                    <li className='flex-fill pb-2'><a className={whatwedo.links.class} href={whatwedo.link1.href}>{whatwedo.link1.copy}</a></li>
                    <li className='flex-fill pb-2'><a className={whatwedo.links.class} href={whatwedo.link2.href}>{whatwedo.link2.copy}</a></li>
                    <li className='flex-fill pb-2'><a className={whatwedo.links.class} href={whatwedo.link3.href}>{whatwedo.link3.copy}</a></li>
                    <li className='flex-col pb-2'><a className={whatwedo.links.class} href={whatwedo.link4.href}>{whatwedo.link4.copy}</a></li>
                </ul>
            </div>
        </section>
    </>
    )
}
  
export default WhatWeDo;