
import 'aos/dist/aos.css';

import About from './About';
import WhatWeDo from './WhatWeDo';
import Studio from './Studio';
import StudioTiles from './StudioTiles';
import Film from './Film';
import FilmTiles from './FilmTiles';
import CreativeTech from './CreativeTech';
import CreativeTechTiles from './CreativeTechTiles';
import AiCollective from './AiCollective';
import AiCollectiveTiles from './AiCollectiveTiles';
import Clients from './Clients';
import AgnosticTech from './AgnosticTech';
import LetsTalk from './LetsTalk';
import Contact from './Contact';


const Main = () => {
    
    return (
        <>
        <section className='container-fluid px-0 bg-blackpearl'>
        <About/>
        <WhatWeDo/>
        <Studio/>
        <StudioTiles/>
        <Film/>
        <FilmTiles/>
        <CreativeTech/>
        <CreativeTechTiles/>
        <AiCollective/>
        <AiCollectiveTiles/>
        </section>
        <section className='container-fluid px-0 bg-blackpearl'>
        <Clients/>
        <AgnosticTech/>
        <LetsTalk/>
        </section>

        <section className='container-fluid px-0 bg-biloba-flower'>

        <Contact/>
        </section>
        </>
)}


export default Main;