import React, { useEffect } from 'react';
import AOS from 'aos';

const film = {
    class:{
        col1:'col-12 col-lg-6 d-flex text-white text-center',
        col2:'col-12 col-lg-6 d-flex align-items-center text-white',
        col3:'col-12 d-flex quartz justify-content-center text-center'
    },
    item1: {
        bg:'./images/film.png',
        copy:'FILM',
        class:'article-thumbnail article-thumbnail-color inter-extra-bold fs-40 fs-lg-60 lh-100',
    },
    item2: {
        copy:<>In our Film department, we capture moments that matter. <b>MADE THIS</b> in-house filmmakers blend cinematic quality with storytelling that connects, creating pieces that move and inspire. From powerful brand narratives to intricate visual sequences, our team leverages every frame to tell stories that leave a lasting impact. Let us bring your brand's voice to life through film content that is as visually stunning as it is emotionally resonant.</>,
        class:'inter-regular scotch-mist fs-20'
    },
    item3: {
        copy:'Primary & Virtual Production + Directing/Shooting + Photography + Post Production + Audio',
        class:'inter-bold fs-20'
    }
}

const Film = () => {

        useEffect(() => {
        AOS.init({ duration: 500 });
        }, []);

        const fadeUp = { 'data-aos': 'fade-up' };
       
    return (
    <>       
        <section className='container px-4 py-5 px-lg-100 pt-lg-100 article'>
            <span className='anchor' id={`film`}></span>
            <div className='row g-5'>
                <div className={film.class.col1}>
                <div className='article-thumbnail-bg' data-aos-ignore='true'>
                        <div className='article-thumbnail-bg' style={{ backgroundImage: `url(${film.item1.bg})` }}  {...fadeUp}>
                            <div className={film.item1.class} >{film.item1.copy}</div>
                        </div>
                    </div>
                </div>
                <div className={film.class.col2} {...fadeUp}>
                    <div className={film.item2.class}>{film.item2.copy}</div>
                </div>
                <div className={film.class.col3} {...fadeUp}>
                    <div className={film.item3.class}>{film.item3.copy}</div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Film;