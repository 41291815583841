import React, { useEffect } from 'react';
import AOS from 'aos';

const studio = {
    class:{
        col1:'col-12 col-lg-6 d-flex text-white text-center',
        col2:'col-12 col-lg-6 d-flex align-items-center text-white',
        col3:'col-12 d-flex quartz justify-content-center text-center'
    },
    item1: {
        bg:'./images/studio.png',
        copy:'STUDIO',
        class:'article-thumbnail article-thumbnail-color inter-extra-bold fs-40 fs-lg-60 lh-100',
    },
    item2: {
        copy:`Our Studio transforms ideas into impactful campaigns across print and digital - we deliver on volume like it's second nature, it's in our DNA. From bespoke print collateral to elaborate dynamic digital assets, we ensure consistency, speed and craft at every touchpoint. Our speed and volume comes from leveraging automation and AI, married with human craft, to not only deliver large amounts of assets but to also help our clients achieve personalisation at scale, tailored to their content strategy.`,
        class:'inter-regular scotch-mist fs-20'
    },
    item3: {
        copy:'Design + Finished Art + Retouching + Animation + Offshore Global Hubs',
        class:'inter-bold fs-20'
    }
}

const Studio = () => {

    useEffect(() => {
    AOS.init({ duration: 500 });
    }, []);

    const fadeUp = {'data-aos':'fade-up'};

    return (
        <>       
        <section className='container px-4 py-5 px-lg-100 pt-lg-100 article'>
            <span className='anchor' id={`studio`}></span>
            <div className='row g-5 flex-row-reverse'>
                <div className={studio.class.col1}>
                    <div className='article-thumbnail-bg' data-aos-ignore='true'>
                        <div className='article-thumbnail-bg' style={{ backgroundImage: `url(${studio.item1.bg})` }}  {...fadeUp}>
                            <div className={studio.item1.class} >{studio.item1.copy}</div>
                        </div>
                    </div>
                </div>
                <div className={studio.class.col2} {...fadeUp}>
                    <div className={studio.item2.class}>{studio.item2.copy}</div>
                </div>
                <div className={studio.class.col3} {...fadeUp}>
                    <div className={studio.item3.class}>{studio.item3.copy}</div>
                </div>
            </div>
        </section>
        </>
        )
}

export default Studio;