import { useState, useEffect, useCallback } from 'react';
import './logo.scss';


const logoSvgs ={
    M : <><svg width='54' height='48' viewBox='0 0 54 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M17.8521 44.2374H0.852051V47.9249H18.9896L17.8521 44.2374Z' fill='white'/> <path d='M53.9392 44.2374H36.9392L35.8142 47.9249H53.9392V44.2374Z' fill='white'/> <path d='M22.7646 41.625H32.0396L40.3021 15.35C41.5521 11.3625 42.6271 7.73745 43.5271 4.46245C43.2521 11 43.1271 18.7125 43.1271 27.575V41.625H53.9521V0.0749512H35.0146L30.7021 14.1375C29.6271 17.95 28.5521 21.975 27.4771 26.2375C26.3521 21.9375 25.2646 17.975 24.1896 14.3375L19.7896 0.0749512H0.852051V41.625H11.6771V27.575C11.6771 19.0125 11.5646 11.3375 11.3396 4.52495C12.0521 7.21245 13.1146 10.825 14.5021 15.35L22.7646 41.625Z' fill='white'/> </svg></>,

    A : <><svg width='51' height='48' viewBox='0 0 51 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M32.0766 44.2374H18.5766L17.2141 47.9249H33.4641L32.0766 44.2374Z' fill='white'/> <path d='M0.964111 41.625H12.5891L15.3391 33.2875H35.2266L38.0516 41.625H50.0766L34.4516 0.0749512H16.2516L0.964111 41.625ZM25.2266 3.51245C27.0641 9.16245 28.4016 13.1875 29.2641 15.6125L32.0141 23.8125H18.5016L21.2516 15.6125C22.1016 13.15 23.4266 9.11245 25.2141 3.51245H25.2266Z' fill='white'/> </svg> </>,

    D : <><svg width='45' height='48' viewBox='0 0 45 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M33.3145 47.1999C29.6395 45.2249 25.1395 44.2374 19.802 44.2374H0.177002V47.9249H34.552C34.152 47.6749 33.7395 47.4249 33.302 47.1999H33.3145Z' fill='white'/> <path d='M0.189453 41.6249H19.8145C25.1395 41.6249 29.652 40.6374 33.327 38.6624C37.002 36.6874 39.7395 33.9124 41.5645 30.3249C43.377 26.7374 44.2895 22.5249 44.2895 17.6874C44.2895 12.8499 43.377 8.63744 41.5645 5.04994C40.602 3.16244 39.3895 1.49994 37.9145 0.062439H0.201956V41.6124L0.189453 41.6249ZM19.6145 3.38744C23.652 3.38744 26.7895 4.56244 29.052 6.91244C31.3145 9.26244 32.4395 12.8624 32.4395 17.6999C32.4395 22.5374 31.302 26.1374 29.052 28.4874C26.7895 30.8374 23.6395 32.0124 19.6145 32.0124H11.552V3.38744H19.6145Z' fill='white'/> </svg></>,

    E : <><svg width='37' height='48' viewBox='0 0 37 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M37.0019 44.2374H0.514404V47.9249H37.0019V44.2374Z' fill='white'/> <path d='M0.514404 41.625H37.0019V31.475H11.8769V22.2H34.7894V12.5875H11.8769V3.92495H37.0019V0.0749512H0.514404V41.625Z' fill='white'/> </svg></>,

    dots : <><svg width='15' height='40' viewBox='0 0 15 40' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M-0.00756836 7.3886C-0.00756836 3.40933 3.38644 0.0153198 7.48333 0.0153198C11.5802 0.0153198 14.9154 3.40933 14.9154 7.3886C14.9154 11.3679 11.5214 14.7619 7.48333 14.7619C3.44525 14.7619 -0.00756836 11.4267 -0.00756836 7.3886ZM-0.00756836 32.6672C-0.00756836 28.6291 3.38644 25.294 7.48333 25.294C11.5802 25.294 14.9154 28.6291 14.9154 32.6672C14.9154 36.7053 11.5214 39.9817 7.48333 39.9817C3.44525 39.9817 -0.00756836 36.6465 -0.00756836 32.6672Z' fill='#A896FF'/> </svg> </>,

    T : <><svg width='42' height='48' viewBox='0 0 42 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0.639648 0.0749512H41.4272V10.225H26.7147V47.925H15.2897V10.225H0.639648V0.0749512Z' fill='white'/> </svg></>,

    H : <><svg width='43' height='48' viewBox='0 0 43 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M31.4518 18.425V0.0749512H42.8143V47.925H31.4518V28.5H12.2268V47.925H0.864258V0.0749512H12.2268V18.425H31.4518Z' fill='white'/> </svg></>,
    
    I : <><svg width='12' height='48' viewBox='0 0 12 48' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M11.4272 47.925H0.0646973V0.0749512H11.4272V47.925Z' fill='white'/> </svg></>,

    S : <><svg width='42' height='49' viewBox='0 0 42 49' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M9.86466 47.9874C6.72716 46.6374 4.36464 44.6999 2.77714 42.1749C1.18964 39.6499 0.364648 36.6499 0.327148 33.1999H11.3522C11.4022 36.1999 12.2772 38.2749 14.0022 39.4124C15.7272 40.5499 18.1397 41.1249 21.2272 41.1249C26.9647 41.1249 29.8272 39.2874 29.8272 35.6124C29.8272 34.5374 29.5646 33.6499 29.0521 32.9624C28.5396 32.2624 27.6647 31.6874 26.4272 31.2124C25.1897 30.7374 23.4646 30.2999 21.2146 29.8999L17.3146 29.1624C12.1146 28.2624 8.15216 26.5999 5.41466 24.1499C2.67716 21.7124 1.31466 18.3874 1.31466 14.1749C1.31466 11.3999 2.01462 8.94993 3.42712 6.81243C4.83962 4.68743 6.93965 3.01243 9.73965 1.79993C12.5396 0.587427 15.9521 -0.0125732 19.9896 -0.0125732C26.4021 -0.0125732 31.2646 1.33743 34.6021 4.04993C37.9396 6.76243 39.7147 10.5999 39.9397 15.5749H29.1896C29.0521 13.2874 28.2897 11.5999 26.9022 10.4999C25.5147 9.39993 23.2772 8.84993 20.1772 8.84993C17.7522 8.84993 15.9271 9.27493 14.7021 10.1249C13.4646 10.9749 12.8521 12.1624 12.8521 13.6874C12.8521 14.8124 13.1271 15.7249 13.6646 16.4374C14.2021 17.1499 15.0397 17.7499 16.1897 18.2124C17.3397 18.6749 18.9271 19.1249 20.9896 19.5249L25.0272 20.2624C28.8772 20.9749 32.0147 21.9499 34.4397 23.1874C36.8647 24.4249 38.6521 25.9874 39.8146 27.8874C40.9771 29.7874 41.5646 32.1124 41.5646 34.8374C41.5646 38.1499 40.7397 40.9249 39.0772 43.1749C37.4147 45.4124 35.0647 47.1124 32.0147 48.2499C28.9647 49.3874 25.3897 49.9624 21.2647 49.9624C16.7897 49.9624 12.9771 49.2874 9.83963 47.9499L9.86466 47.9874Z' fill='white'/> </svg></>,

    Mob : <><svg xmlns='http://www.w3.org/2000/svg' width='132' height='50' viewBox='0 0 132 50' fill='none'> <path d='M88.6865 0H131.313V10.6049H115.934V50H103.996V10.6049H88.6865V0Z' fill='white'/> <path d='M66.9924 12.3886C66.9924 8.40933 70.3864 5.01532 74.4833 5.01532C78.5802 5.01532 81.9154 8.40933 81.9154 12.3886C81.9154 16.3679 78.5214 19.7619 74.4833 19.7619C70.4453 19.7619 66.9924 16.4267 66.9924 12.3886ZM66.9924 37.6672C66.9924 33.6291 70.3864 30.294 74.4833 30.294C78.5802 30.294 81.9154 33.6291 81.9154 37.6672C81.9154 41.7053 78.5214 44.9817 74.4833 44.9817C70.4453 44.9817 66.9924 41.6465 66.9924 37.6672Z' fill='#A896FF'/> <path d='M17.7653 46.1495H0V50H18.9499L17.7653 46.1495Z' fill='white'/> <path d='M55.475 46.1495H37.7097L36.528 50H55.475V46.1495Z' fill='white'/> <path d='M22.8927 43.4136H32.5847L41.2209 15.9563C42.5315 11.7894 43.6544 7.99776 44.5925 4.58135C44.3125 11.417 44.1697 19.4679 44.1697 28.7371V43.4136H55.4747V0H35.6875L31.1789 14.6906C30.056 18.6698 28.9331 22.8843 27.8073 27.3313C26.6368 22.8367 25.4887 18.695 24.3657 14.9006L19.79 0H0V43.4136H11.305V28.7371C11.305 19.7956 11.1873 11.767 10.9549 4.65136C11.7026 7.4601 12.8031 11.2293 14.2565 15.9563L22.8927 43.4136Z' fill='white'/> </svg></>
}

const Logo = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [step, setStep] = useState(0); // Tracks animation steps

  // Memoize the handleScroll function
  const handleScroll = useCallback(() => {
    const currentScroll = window.scrollY;

    if (currentScroll > scrollPosition) {
      // Scrolling down
      if (step < 4) setStep(prevStep => prevStep + 10); // Use prevStep to ensure correct state update
    } else {
      // Scrolling up
      if (step > 0) setStep(prevStep => prevStep - 10); // Use prevStep here too
    }

    setScrollPosition(currentScroll);
  }, [scrollPosition, step]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]); // Add handleScroll as the dependency

  return (
    <>
    <div className='container justify-content-center fixed-top d-flex d-md-none pt-4'>
      {logoSvgs.Mob}
    </div>


    <div className='logo-container fixed-top d-none d-md-flex pt-4'>
      <div className={`row-container ${step === 3 ? 'closer' : ''}`}>
        <div className={`colLogo z-3 ${step >= 4 ? 'move-right-3' : ''}`}>{logoSvgs.M}</div>
        <div className={`colLogo ${step >= 3 ? 'move-right-2' : ''}`}>{logoSvgs.A}</div>
        <div className={`colLogo ${step >= 2 ? 'move-right-1' : ''}`}>{logoSvgs.D}</div>
        <div className={`colLogo ${step >= 1 ? 'move-right-1' : ''}`}>{logoSvgs.E}</div>
        <div className={`colLogo z-3`}>{logoSvgs.dots}</div>
        <div className={`colLogo z-3`}>{logoSvgs.T}</div>
        <div className={`colLogo ${step >= 2 ? 'move-left-1' : ''}`}>{logoSvgs.H}</div>
        <div className={`colLogo ${step >= 3 ? 'move-left-2' : ''}`}>{logoSvgs.I}</div>
        <div className={`colLogo ${step >= 4 ? 'move-left-3' : ''}`}>{logoSvgs.S}</div>
      </div>
      <div style={{ height: '200vh' }}>
        {/* Placeholder to create scrolling space */}
      </div>
    </div>
    </>
  )
};

export default Logo;
