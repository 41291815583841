import React, { useEffect } from 'react';
import AOS from 'aos';

const studiotiles = {
    colClass:'col-12 col-lg-4 px-0 d-flex align-items-center justify-content-center text-center inter-bold fs-20 purple-heart',
    item1 : {
        copy:null,
        bg:'./images/studio-img-01.gif',
        class:'tiles-thumbnail me-lg-4 cursor-no'
    },
    item2 : {
        copy:null,
        bg:'./images/studio-img-02.png',
        class:'tiles-thumbnail mx-lg-3 cursor-no'
    },
    item3 : {
        copy:null,
        bg:'./images/studio-img-03.png',
        class:'tiles-thumbnail ms-lg-4 cursor-no'
    }
}

const StudioTiles = () => {

  useEffect(() => {
  AOS.init({ duration: 500 });
  }, []);
    
  const fadeUp = {'data-aos':'fade-up'};

  return (
    <section className='container px-4 pb-5 px-lg-100 pb-lg-100 tiles'>
       <div className='row g-5 w-100 justify-content-evenly'>
        {/*Col1*/}
        <div className={studiotiles.colClass} {...fadeUp}>
          <div 
            className={studiotiles.item1.class} style={{ backgroundImage: `url(${studiotiles.item1.bg})` }}
          >          
           {studiotiles.item1.copy}
          </div>
        </div>
        {/*Col2*/}
        <div className={studiotiles.colClass} {...fadeUp}>
          <div 
            className={studiotiles.item2.class} style={{ backgroundImage: `url(${studiotiles.item2.bg})` }} 
          >          
           {studiotiles.item2.copy}
          </div>
        </div>
        {/*Col3*/}
        <div className={studiotiles.colClass} {...fadeUp}>
          <div 
            className={studiotiles.item3.class} style={{ backgroundImage: `url(${studiotiles.item3.bg})` }} 
          >
            {studiotiles.item3.copy}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudioTiles;
