import React, { useEffect } from 'react';
import AOS from 'aos';

import './letstalk.scss';

const letstalk = {
    id:'letstalk',
    class:'letstalkCta open-sans-extra-bold',
    copy:<>LET'S TALK</>,
    href:'mailto:hello@madethis.com.au'
}

const LetsTalk = () => {
    
    useEffect(() => {
    AOS.init({ duration: 500 });
    }, []);
        
    const fadeUp = {'data-aos':'fade-up'};

    return (
    
    <>
        <section className='container px-4 pb-2 px-lg-100 mb-lg-100 mb-5' {...fadeUp}>
            <div className='col-12 d-flex justify-content-center m-0 p-0 pb-3 '>
                <a className={letstalk.class} href={letstalk.href}>
                    {letstalk.copy}
                </a>
            </div>
        </section>
    </>
    )
}
  
export default LetsTalk;