
import Menu from './Menu';
import Home from './Home';
import Logo from './Logo';

import Main from './Main';
// import { Analytics } from '@vercel/analytics/react';

const App = () => {
  return (
    <div className='container-fluid p-0 m-0 min-vh-100 d-flex flex-wrap'>
      <div className='container-fluid p-0 m-0 d-flex flex-wrap'>
        <Home />
        <Logo />
        <Menu />
        <Main />
      </div>
      {/* <Analytics /> */}
    </div>
  )
}

export default App
