import './menu.scss';
import React, { useState, useEffect } from 'react';

const menuIcon = <svg width='15' height='40' viewBox='0 0 15 40' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0 7.63562C0 3.75487 3.30818 0.459412 7.29072 0.459412C11.2733 0.459412 14.5305 3.76759 14.5305 7.63562C14.5305 11.5036 11.2224 14.8118 7.29072 14.8118C3.35907 14.8118 0 11.5673 0 7.63562ZM0 32.2561C0 28.3245 3.30818 25.0672 7.29072 25.0672C11.2733 25.0672 14.5305 28.3118 14.5305 32.2561C14.5305 36.2005 11.2224 39.3814 7.29072 39.3814C3.35907 39.3814 0 36.1369 0 32.2561Z' fill='#A896FF'/> </svg>

const menuIconOn = <svg width='56' height='51' viewBox='0 0 56 51' fill='none' xmlns='http://www.w3.org/2000/svg'> <rect x='0.75' y='6.21429' width='54.2857' height='14.2857' rx='7.14286' fill='#A896FF'/> <rect x='0.75' y='30.5' width='54.2857' height='14.2857' rx='7.14286' fill='#A896FF'/> </svg>

const menuItems = {
  class:'nav-link text-white px-3 inter-extra-bold fs-34 fs-lg-46',
  item1: {
    copy:'ABOUT',
    href:'#about'
  },
  item2: {
    copy:' WHAT WE DO ',
    href:'#whatwedo'
  },
  item3: {
    copy:'CONTACT',
    href:'#contact'
  }
}

const Menu = () => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 100) { 
        setScrolled(true);
        } 
        else {
        setScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, 
    []);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    const handleMenuItemClick = () => {
      setIsOpen(false);
    };

  return (
  <>
    <div className='container-fluid'>
        <div className='navbarBg container-fluid fixed-top '></div>
        <nav className='navbar fixed-top navbar-expand-lg py-0'>
            <div className={`container-fluid flex-row-reverse p-0  ${scrolled ? 'menuScrolled' : ''}`}>
                <div className='menu-container container'>
                    {/* Burger icon changes to 'X' on click */}
                    <div className='d-flex pt-4'>
                      <button className='menu-toggle' aria-label='Menu' onClick={toggleMenu}>
                          {isOpen ? menuIconOn : menuIcon}
                      </button>
                      </div>
                    <div className={`menu-overlay bg-purple-heart ${isOpen ? 'open' : ''} gx-0`}>
                        <nav className='menu'>
                            <ul>
                                <li>
                                  <a onClick={handleMenuItemClick} className={menuItems.class}
                                    href={menuItems.item1.href}>{menuItems.item1.copy}</a>
                                </li>
                                <li>
                                  <a onClick={handleMenuItemClick} className={menuItems.class}
                                    href={menuItems.item2.href}>{menuItems.item2.copy}</a>
                                  </li>
                                <li>
                                  <a onClick={handleMenuItemClick} className={menuItems.class}
                                    href={menuItems.item3.href}>{menuItems.item3.copy}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    </>
  );
};

export default Menu;
