import React, { useEffect } from 'react';
import AOS from 'aos';

const about = {
    id:'about',
    class:{
        item:'col-12 col-lg-6 p-0 pb-3',
    },
    item1: {
        copy:<>Not <i>just</i> a <br/>production <br/>company</>,
        class:'laserlemon inter-extra-bold fs-45 fs-lg-56 lh-120'
    },
    item2: {
        copy:<>
            <p className='pb-3'>It's where <b>innovation meets artistry,</b><br/>it's where the <b>impossible</b> is <b>made real</b>.</p>
            <p className='pb-3'>We are the <b>architects of extraordinary experiences</b>.</p>
            <p className='pb-3'>With a focus on <b>craft</b> and a <b>passion</b> for <b>pushing the limits</b>, <b>we bring visions <br/>to life</b> in ways that <b>defy expectations</b>.</p>
            <p className='pb-3'>Our driving force is to marry craft with <b>innovation</b>, deliver on <b>personalisation <br/>at scale</b> and making sure we are cost <br/>effective by leveraging <b>tech, data, <br/>automation</b> and <b>AI</b>.</p>
            </>,
        class:'text-white inter-regular fs-20 gy-3 fs-lg-26'
    }
}

const About = () => {

    useEffect(() => {
            AOS.init({ duration: 500 });
          }, []);
    
    const fadeUp = {'data-aos':'fade-up'};

    return (
        <>
            <section {...fadeUp} className='container px-4 py-5 p-lg-100 about'>
                <span className='anchor' id={`about`}></span>
                <div className='row m-0'>
                    <div className={about.class.item}>
                        <div  className={about.item1.class}>{about.item1.copy}</div>
                    </div>
                    <div className={about.class.item}>
                    <div className={about.item2.class}>{about.item2.copy}</div>
                    </div>
                </div>
            </section>
        </>
    )
}
  
export default About;