import React, { useEffect } from 'react';
import AOS from 'aos';

const aicollective = {
    class:{
        col1:'col-12 col-lg-6 d-flex text-white text-center',
        col2:'col-12 col-lg-6 d-flex align-items-center text-white',
        col3:'col-12 d-flex quartz justify-content-center text-center'
    },
    item1: {
        bg:'./images/aicollective.png',
        copy:<>AI ARTIST<br/>COLLECTIVE</>,
        class:'article-thumbnail article-thumbnail-color inter-extra-bold fs-40 fs-lg-60 lh-100'
    },
    item2: {
        copy:<>The <b>MADE THIS</b> AI Artist Collective brings forward a new breed of content creators and creative makers from all around the world to bring unique ideas to life. The purpose of the Collective is to break the mold of the traditional director rosters and traditional ways of producing content as AI becomes more and more prominent in our content workflows. It's here to empower bold and unique art forms, pushing the boundaries of creativity and innovation to its bleeding edge and and allow us to deliver work that truly embodies human craft with technology.</>,
        class:'inter-regular scotch-mist fs-20'
    },
    item3: {
        copy:'Creative And Tech solutions + AI Workflows + Immersive Experiences + AI Content Creation',
        class:'inter-bold fs-20'
    }
}

const AiCollective = () => {

        useEffect(() => {
        AOS.init({ duration: 500 });
        }, []);

        const fadeUp = {'data-aos':'fade-up'};
       
    return (
    <>       
        <section className='container px-4 py-5 px-lg-100 pt-lg-100 article'>
            <span className='anchor' id={`aicollective`}></span>
            <div className='row g-5'>
                <div className={aicollective.class.col1}>
                <div className='article-thumbnail-bg' data-aos-ignore='true'>
                        <div className='article-thumbnail-bg' style={{ backgroundImage: `url(${aicollective.item1.bg})` }}  {...fadeUp}>
                            <div className={aicollective.item1.class} >{aicollective.item1.copy}</div>
                        </div>
                    </div>
                </div>
                <div className={aicollective.class.col2} {...fadeUp}>
                    <div className={aicollective.item2.class}>{aicollective.item2.copy}</div>
                </div>
                <div className={aicollective.class.col3} {...fadeUp}>
                    <div className={aicollective.item3.class}>{aicollective.item3.copy}</div>
                </div>
            </div>
        </section>
    </>
    )
}

export default AiCollective;